import * as microsoftTeams from "@microsoft/teams-js";
import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { encode as base64encode } from "base64-arraybuffer";
import Vars from "../Vars";

interface GetOBOTokensResponse
{
    graph: {
        access_token: string;
        refresh_token: string; // has value if offline_access scope is requested
        expires_in: number;
        ext_expires_in: number;
        scope: string;
        token_type: string;
    };
    sp?: {
        access_token: string;
        refresh_token: string; // has value if offline_access scope is requested
        expires_in: number;
        ext_expires_in: number;
        scope: string;
        token_type: string;
    };
}

interface TokenResponse
{
    access_token: string;
    refresh_token: string;
    expires_in: number,
    ext_expires_in: number;
    id_token?: string;
    scope: string;
    token_type: string;
}

export class TeamsAuth
{
    private static  CLIENT_ID = Vars.CLIENT_ID;
    private static TOKEN_ENDPOINT = '/api/GetOBOTokens';
    //private static TOKEN_ENDPOINT = 'https://bsb-news-connector-test.azurewebsites.net/api/GetOBOTokens?code=KTAA989pWAfYQSdUNeK9mzklYlXrGyEuinaja5M5muZPhdEh35xSMg==';

    public static async ssoAuthenticate()
    {
        return new Promise<string>((resolve, reject) => 
        {
            const authRequestOptions: microsoftTeams.authentication.AuthTokenRequest = {
                successCallback: ssoToken => resolve(ssoToken),
                failureCallback: error => reject(error)
            };
    
            microsoftTeams.authentication.getAuthToken(authRequestOptions);
        });
    }

    public static async authorize()
    {
        const params = new URLSearchParams({
            scopes: this.CLIENT_ID,
            mode: 'authorize'
        });

        return new Promise<string>((resolve, reject) => 
        {
            microsoftTeams.authentication.authenticate({
                url: `${window.location.origin}/auth/login?${params.toString()}`,
                successCallback: async (accessToken: string) => {
                    resolve(accessToken)
                },
                failureCallback: (reason) => {
                    alert(reason);
                    reject(reason);
                }
            });
        });
    }

    public static async getAccessTokens(assertionToken: string, graphScopes: string[], spScopes?: string[]): Promise<GetOBOTokensResponse>
    {
        try
        {
            const response = await axios.post<any, AxiosResponse<GetOBOTokensResponse>>(this.TOKEN_ENDPOINT, { graphScopes, spScopes, assertion: assertionToken }, {
                // headers: {
                //     'authorization': `Bearer ${assertionToken}`
                // },
            });

            return response.data;
        }
        catch(error: any)
        {
            if(error.response)
            {
                const data = error.response.data;
                if(data.error === 'consent_required')
                {
                    let params = { mode: 'consent' };
                    if(graphScopes && graphScopes.length > 0)
                        params['graphScopes'] = graphScopes.join(' ');
                    if(spScopes && spScopes.length > 0)
                        params['spScopes'] = spScopes.join(' ');
                    
                    const queryStringParams = new URLSearchParams(params);

                    return new Promise((resolve, reject) => 
                    {
                        // attempt login again to show consent screen
                        microsoftTeams.authentication.authenticate({
                            url: `${window.location.origin}/auth/login?${queryStringParams.toString()}`,
                            successCallback: async (result) => 
                            {
                                resolve(await this.getAccessTokens(assertionToken, graphScopes, spScopes));
                            },
                            failureCallback: (reason) => 
                            {
                                alert(reason);
                                reject(reason);
                            }
                        });
                    });
                }
                else
                {
                    // show error
                    console.log(error);
                    alert('Error received while retrieving tokens');
                }
            }
            else if(error.request)
            {
                throw new Error('No response received from remote server');
            }
            else
            {
                throw error;
            }
        }
    }

    public static redirectToConsentPage(tenantId: string, scopes: string[], loginHint: string = undefined)
    {
        const authState = uuidv4();
        sessionStorage.setItem('auth-state', authState);

        const nonce = uuidv4();
        sessionStorage.setItem('auth-nonce', nonce);

        const accessTokenQueryParams = new URLSearchParams({
            prompt: 'consent',
            client_id: this.CLIENT_ID,
            response_type: 'id_token',
            response_mode: 'fragment',
            scope: scopes.join(' '),
            redirect_uri: `${window.location.origin}/auth/callback`,
            nonce: nonce,
            state: authState,
            login_hint: loginHint
        });

        window.location.assign(`https://login.microsoftonline.com/${tenantId}/oauth2/authorize?${accessTokenQueryParams.toString()}`);
    }

    public static redirectToLogoutPage(tenantId: string)
    {
        window.location.assign(`https://login.microsoftonline.com/${tenantId}/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`)
    }

    public static async redirectToAuthorizePage(tenantId: string, scopes: string[] = [this.CLIENT_ID], resource: string = this.CLIENT_ID)
    {
        const authState = uuidv4();
        sessionStorage.setItem('auth-state', authState);

        const codeVerifier = this.randomString(128);
        const challenge = await this.generateCodeChallenge(codeVerifier);

        sessionStorage.setItem('auth-code-verifier', codeVerifier);

        const accessTokenQueryParams = new URLSearchParams({
            client_id: this.CLIENT_ID,
            response_type: 'code',
            response_mode: 'query',
            scope: scopes.join(' '),
            resource: resource,
            redirect_uri: `${window.location.origin}/auth/callback`,
            state: authState,
            code_challenge: challenge,
            code_challenge_method: 'S256'
        });

        window.location.assign(`https://login.microsoftonline.com/${tenantId}/oauth2/authorize?${accessTokenQueryParams.toString()}`);
    }

    public static async exchangeCodeForToken(code: string, codeVerifier: string, tenantId: string)
    {
        const data = new URLSearchParams({
            client_id: this.CLIENT_ID,
            scope: this.CLIENT_ID,
            resource: this.CLIENT_ID,
            code: code,
            redirect_uri: `${window.location.origin}/auth/callback`,
            grant_type: 'authorization_code',
            code_verifier: codeVerifier,
        }).toString();

        try
        {
            const response = await axios.post<any, AxiosResponse<TokenResponse>>(`https://login.microsoftonline.com/${tenantId}/oauth2/token`, data, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            });
    
            return response.data;
        }
        catch(ex)
        {
            console.error(ex);
            throw ex;
        }
    }

    private static async generateCodeChallenge(codeVerifier: string)
    {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window.crypto.subtle.digest('SHA-256', data);

        const base64Digest = base64encode(digest);

        return base64Digest
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    }

    private static randomString(length: number)
    {
        let retval = '';
        const chars = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');

        for(let i = 0; i < length; i++)
            retval += chars[Math.floor(Math.random() * chars.length)];

        return retval;
    }
}