import axios from "axios";
import NewsConnection from "../dto/NewsConnection";

const API_CONNECTIONS = '/api/connections';

export default class DataService
{
    private static _current: DataService = null;
    public static current()
    {
        return this._current ? this._current : this._current = new DataService();
    }

    public async addConnection(connectionInfo: NewsConnection)
    {
        try
        {
            const response = await axios.post(API_CONNECTIONS, connectionInfo);

            return response.data;
        }
        catch(ex)
        {
            console.error(ex);
            throw ex;
        }
    }

    public async removeConnection(entityId: string)
    {
        try
        {
            const response = await axios.delete(API_CONNECTIONS + `/${entityId}`);
            
            return response.data;
        }
        catch(ex)
        {
            console.error(ex);
            throw ex;
        }
    }
}